export const projectdata = [
  {
    id: 1,
    projectTitle: "Jiwan Kosh - A fund raising website",
    link: "www",
  },
  {
    id: 2,
    projectTitle: "OnlineStore",
    link: "www",
  },
];
